import { Permissions, Role } from './Role';

export enum AdminStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface Permission {
  id: number
  name: string
  guard_name: string
  created_at: string
  updated_at: string
  menu: string
  title: string
  route: string
}

export interface Admin {
  id: number
  username: string
  name: string
  phone: string
  status: AdminStatus
  created_at: string
  updated_at: string
  access_token: string
  token_type: string
  roles: Role[]
  permissions: Permission[]
}

export type PartialAdmin = Partial<Admin>;
