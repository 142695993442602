
import { defineComponent, ref } from 'vue';
import { useAdvertisements } from '@/composables/api';
import permissionUnits from '@/components/permission-units/index.vue';

export default defineComponent({
  components: {
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const { data, isLoading, isFetching } = useAdvertisements({ page });

    return { data, isLoading, isFetching, page };
  }
});
