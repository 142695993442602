
import { defineComponent, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useAdmins, useDeleteAdmin, useEnableAdmin, useDisableAdmin, useResetQRCodeAdmin } from '@/composables/api';
import { AdminIdOptions } from '@/services/api';
import { AdminStatus } from '@/interfaces/Admin';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Enable',
        value: AdminStatus.ENABLE
      },
      {
        label: 'Disable',
        value: AdminStatus.DISABLE
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref('');
    const status = ref();

    const { data, isLoading, isFetching, refetch } = useAdmins({ page, status, keyword });
    const { isLoading: isDeletedLoading, mutate } = useDeleteAdmin();
    const { isLoading: isResetLoading, mutate: reset } = useResetQRCodeAdmin();
    const { isLoading: isEnableLoading, mutate: enableMutate } = useEnableAdmin();
    const { isLoading: isDisableLoading, mutate: disableMutate } = useDisableAdmin();

    const statusIndex = ref(undefined);
    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };
    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
    };

    const deleteAdmin = ({ adminId }: AdminIdOptions, index: Number) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          mutate(
            { adminId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const resetQRCodeAdmin = ({ adminId }: AdminIdOptions, index: Number) => {
      ElMessageBox.confirm('Are you sure you want to reset QRcode?', 'Warning', {
        confirmButtonText: 'Reset',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          statusIndex.value = index;
          reset(
            { adminId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Reset successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const enableAdmin = ({ adminId }: AdminIdOptions, index: Number) => {
      statusIndex.value = index;
      enableMutate({ adminId }, {
        onSuccess() {
          refetch.value();
          ElMessage({
            type: 'success',
            message: 'Enable successfully'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.message,
            type: 'error'
          });
        }
      });
    };

    const disableAdmin = ({ adminId }: AdminIdOptions, index: Number) => {
      statusIndex.value = index;
      console.log('disableAdmin', statusIndex.value);

      disableMutate({ adminId }, {
        onSuccess() {
          refetch.value();
          ElMessage({
            type: 'success',
            message: 'Enable successfully'
          });
        },
        onError(error: any) {
          ElMessage({
            message: error.response?.data.message,
            type: 'error'
          });
        }
      });
    };

    return {
      AdminStatus,
      page,
      isLoading,
      isFetching,
      data,
      isDeletedLoading,
      isResetLoading,
      deleteAdmin,
      resetQRCodeAdmin,
      isEnableLoading,
      isDisableLoading,
      enableAdmin,
      disableAdmin,
      statusIndex,
      searchKeyword,
      handleFilterChange,
      FILTER_OPTIONS
    };
  }
});
